import {InfinityScrollSpin} from '../../../../shared/ui/infinityScrollSpin/InfinityScrollSpin';
import ChatListItemsEmpty from './components/ChatListItemsEmpty/ChatListItemsEmpty';
import {TTabs, useChatListState} from '../../state/useChatListState';
import ChatListItem from './components/ChatListItem/ChatListItem';
import {ChatListItemsStyled} from './assets/styles';
import {useTranslation} from 'react-i18next';
import {IChat} from '../../../../interfaces';
import React, {useMemo} from 'react';
import styled from 'styled-components';

type TChatListItems = {
    items: IChat[];
    typeTabs: TTabs;
};

const ChatListItems = ({items, typeTabs}: TChatListItems) => {
    const {i18n} = useTranslation();

    const isLoadingLastChat = useChatListState(
        (state) => state.isLoadingLastChat
    );

    const setIsLoadingLastChat = useChatListState(
        (state) => state.setIsLoadingLastChat
    );

    const getPartnersChatsList = useChatListState(
        (state) => state.getPartnersChatsList
    );

    const phexChatsTotalPages = useChatListState(
        (state) => state.phexChatsTotalPages
    );

    const chatListTotalPages = useChatListState(
        (state) => state.chatListTotalPages
    );

    const getPhexChatsList = useChatListState((state) => state.getPhexChatsList);
    const setCurrentPage = useChatListState((state) => state.setCurrentPage);
    const currentPage = useChatListState((state) => state.currentPage);

    const loadMoreItems = () => {
        setIsLoadingLastChat(true);
        setCurrentPage(currentPage + 1);

        if (typeTabs === 'personal') {
            getPartnersChatsList().then();
        } else {
            getPhexChatsList().then();
        }
    };

    const renderChatListItem = (item: IChat) => {
        return <ChatListItem typeTabs={typeTabs} item={item} key={item.id}/>;
    };

    const renderChatListEmpty = () => {
        return <ChatListItemsEmpty/>;
    };

    const renderChatList = useMemo(() => {
        if (!!items?.length) {
            return (
                <>
                    {items?.map(renderChatListItem)}

                    <WrapperLoaderStyled>
                        <InfinityScrollSpin
                            isLoading={isLoadingLastChat}
                            incrementPageCallback={loadMoreItems}
                            total={
                                typeTabs === 'personal'
                                    ? chatListTotalPages
                                    : phexChatsTotalPages
                            }
                            currentLengthList={items.length}
                        />
                    </WrapperLoaderStyled>
                </>
            );
        } else {
            return renderChatListEmpty();
        }
    }, [
        items?.length,
        items,
        isLoadingLastChat,
        typeTabs,
        chatListTotalPages,
        phexChatsTotalPages,
        i18n.language,
    ]);

    return <ChatListItemsStyled>{renderChatList}</ChatListItemsStyled>;
};

export default ChatListItems;

const WrapperLoaderStyled = styled.div`
    height: 100px;
`;
