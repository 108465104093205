import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigProvider, Popover } from 'antd';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  HourglassOutlined,
} from '@ant-design/icons';

import {
  RatingContainer,
  RatingInfoResult,
  RatingInfoRow,
  RatingInfoText,
  RatingText,
} from './styles';
import { IExchangePointMetrics } from 'interfaces';

interface RatingTooltipProps {
  metrics?: IExchangePointMetrics;
  children?: ReactNode;
}
export const RatingTooltip = ({ metrics, children }: RatingTooltipProps) => {
  const { t } = useTranslation();

  const ratingItems = [
    {
      key: 0,
      icon: <HourglassOutlined />,
      label: t('Средняя скорость рассмотрения сделки'),
      content: metrics?.avg_minutes_in_pending_requests
        ? `${metrics?.avg_minutes_in_pending_requests} ${t('мин')}`
        : '-',
      hasDivider: true,
    },
    {
      key: 1,
      icon: <CheckCircleOutlined style={{ color: '#36CFC9' }} />,
      label: t('Выполнено'),
      content: metrics?.completed_requests_count ?? '-',
    },
    {
      key: 2,
      icon: <CheckCircleOutlined style={{ color: '#69B1FF' }} />,
      label: t('Не принято'),
      content: metrics?.pending_cancelled_requests_count ?? '-',
    },
    {
      key: 3,
      icon: <ExclamationCircleOutlined style={{ color: '#FFC069' }} />,
      label: t('Открыто споров'),
      content: metrics?.argued_requests_count ?? '-',
    },
    {
      key: 4,
      icon: <ExclamationCircleOutlined style={{ color: '#FFC069' }} />,
      label: t('Проиграно споров'),
      content: metrics?.argued_requests_for_client_count ?? '-',
    },
    {
      key: 5,
      icon: <CloseCircleOutlined style={{ color: '#FF7875' }} />,
      label: t('Отменено клиентом из “Обеспечения гаранта”'),
      content: metrics?.warranty_collateral_cancelled_requests_count ?? '-',
    },
    {
      key: 6,
      icon: <ClockCircleOutlined style={{ color: '#FF7875' }} />,
      label: t('Просрочено'),
      content: metrics?.expired_requests_count ?? '-',
    },
  ];

  const content = (
    <RatingContainer>
      <RatingText>{t('Рейтинг по сделкам с гарантом')}</RatingText>
      {ratingItems.map((item) => (
        <RatingInfoRow $hasDivider={item.hasDivider}>
          {item.icon}
          <RatingInfoText>{item.label}</RatingInfoText>
          <RatingInfoResult>{item.content}</RatingInfoResult>
        </RatingInfoRow>
      ))}
    </RatingContainer>
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Popover: {
            colorBgElevated: '#2B2B36',
          },
        },
      }}
    >
      <Popover content={content} placement="bottom">
        {children}
      </Popover>
    </ConfigProvider>
  );
};
