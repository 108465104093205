import {Navigate, RouteObject} from 'react-router-dom';
import {ROUTER_PATH} from '../path';
import {ACCOUNT_SETTINGS_ROUTES} from './app/account-settings';
import {SAFETY_ROUTES} from './app/safety';
import {VERIFICATION_ROUTES} from './app/verification';
import {CHAT_ROUTES} from './app/chat';
import {useCookiesCustom} from '../../hooks/useCookieCustom';
import AppLayout from "../../components/tools/layouts";

const Base = () => {
    const referrerUrl = new URLSearchParams(window.location.search).get(
        'referrer_url'
    );

    const {cookie} = useCookiesCustom();

    if (referrerUrl) {
        window.localStorage.setItem(
            'referrer_url',
            decodeURIComponent(referrerUrl)
        );
    }

    switch (window.location.pathname) {
        // case ROUTER_PATH.AuthRoutes.SAFETY_INDEX:
        // case ROUTER_PATH.AuthRoutes.ACCOUNT_SETTINGS_INDEX:
        // case ROUTER_PATH.AuthRoutes.SAFETY_2FA:
        // case ROUTER_PATH.AuthRoutes.SAFETY_DEVICES:
        // case ROUTER_PATH.AuthRoutes.SAFETY_IP_PROTECTION:
        case ROUTER_PATH.AuthRoutes.CHANGE_EMAIL:
            return <Navigate to={window.location.pathname}/>;
        default:
            return cookie['token'] ? <Navigate to={ROUTER_PATH.AuthRoutes.MY_DEALS}/> :
                <Navigate to={ROUTER_PATH.FormsPages.LOGIN}/>;
    }
};

const APP_ROUTES: RouteObject[] = [
    {
        path: '/',
        element: <AppLayout/>,
        children: [
            ...ACCOUNT_SETTINGS_ROUTES,
            ...SAFETY_ROUTES,
            ...VERIFICATION_ROUTES,
            ...CHAT_ROUTES,
            {
                index: true,
                element: <Base/>,
            },
        ],
    },
];

export {APP_ROUTES};
