import {
  ChatListItemCompanyStyled,
  ChatListItemCompanyTitleStyled,
  ChatListItemContainerStyled,
} from '../../assets/styles';
import CardTravel from '../../assets/CardTravel.svg';
import UserOutlined from '../../assets/UserOutlined.svg';
import { RatingTooltip } from 'shared/ui/tooltips/RatingTooltip';
import { IExchangePointMetrics } from 'interfaces';

type TChatListItemCompany = {
  companyName?: string;
  adminEmail?: string;
  clientNickname?: string;
  typeTabs?: 'corporate' | 'personal';
  metrics?: IExchangePointMetrics;
};

const ChatListItemCompany = ({
  companyName,
  clientNickname,
  typeTabs,
  metrics,
}: TChatListItemCompany) => {
  return (
    <>
      <ChatListItemContainerStyled>
        <ChatListItemCompanyStyled>
          <img src={CardTravel} alt={'CardTravel'} />
          <RatingTooltip metrics={metrics}>
            <ChatListItemCompanyTitleStyled>
              {companyName}
            </ChatListItemCompanyTitleStyled>
          </RatingTooltip>
        </ChatListItemCompanyStyled>
        {typeTabs === 'corporate' && (
          <ChatListItemCompanyStyled>
            <img src={UserOutlined} alt={'UserOutlined'} />
            <ChatListItemCompanyTitleStyled>
              {clientNickname}
            </ChatListItemCompanyTitleStyled>
          </ChatListItemCompanyStyled>
        )}
      </ChatListItemContainerStyled>
    </>
  );
};

export default ChatListItemCompany;
