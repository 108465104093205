import {
  ChatListDirectionContainerStyled,
  ChatListItemPairStyled,
  ChatListItemTypeStyled,
  GavelStyled,
} from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import Gavel from '../../../../../../../../assets/img/icons/gavel.svg';
import { useMemo } from 'react';
import { Tooltip } from 'antd';
import { TTabs } from 'pages/chat-list/state/useChatListState';

type TChatListItemTypePair = {
  isSellingCrypto?: boolean;
  pair: string;
  isRefereeInChat: boolean;
  typeTabs: TTabs;
};

const ChatListItemTypePair = ({
  isSellingCrypto,
  pair,
  isRefereeInChat,
  typeTabs,
}: TChatListItemTypePair) => {
  const { t, i18n } = useTranslation();

  const renderType = useMemo(() => {
    const translateType = () => {
      if (typeTabs === 'corporate' ? !isSellingCrypto : isSellingCrypto) {
        return t('Продажа');
      } else {
        return t('Покупка');
      }
    };
    return <ChatListItemTypeStyled>{translateType()}</ChatListItemTypeStyled>;
  }, [isSellingCrypto, i18n.language]);

  const renderPair = useMemo(() => {
    return <ChatListItemPairStyled>{pair}</ChatListItemPairStyled>;
  }, [pair, i18n.language]);

  const renderRefereeInChat = useMemo(() => {
    if (isRefereeInChat) {
      return (
        <Tooltip title={t('Рефери тултип')} trigger={'hover'}>
          <GavelStyled src={Gavel} alt={'Gavel'} />
        </Tooltip>
      );
    }
  }, [isRefereeInChat, i18n.language]);

  return (
    <ChatListDirectionContainerStyled>
      {renderType}
      {renderPair}
      {renderRefereeInChat}
    </ChatListDirectionContainerStyled>
  );
};

export default ChatListItemTypePair;
