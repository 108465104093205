import React, {useEffect, useMemo} from 'react';
import './ChatAsideBell.css';
import {CommentOutlined} from '@ant-design/icons';
import {BadgeStyled, DropdownStyled} from './ChatAsideBell.style';
import {ChatAside} from '../ChatAside/ChatAside';
import {useChatAsideState} from 'widgets/chatAside/model/useChatAsideState';
import { getCookieByName } from 'shared/helpers/controlCookies';

export const ChatAsideBell = () => {
    const getChatCounts = useChatAsideState((state) => state.getChatCounts);
    const chatCounts = useChatAsideState((state) => state.chatCounts);

    useEffect(() => {
        getChatCounts();
    }, [getCookieByName('phexChatToken'), getCookieByName('partnerChatToken')]);

    const renderChatCount = useMemo(() => {
        if (!!chatCounts) {
            return chatCounts?.corporate + chatCounts?.personal;
        } else {
            return 0
        }
    }, [chatCounts?.corporate, chatCounts?.personal])

    return (
        <DropdownStyled
            trigger={['click']}
            className={'ant-dropdown-notifications-custom'}
            dropdownRender={() => <ChatAside/>}
            destroyPopupOnHide
        >
            <BadgeStyled
                count={renderChatCount}
                size={'default'}
                color={'linear-gradient(45deg, #088473 0%, #12BFA8 100%)'}
                showZero={true}
                offset={[7, 0]}
            >
                <CommentOutlined/>
            </BadgeStyled>
        </DropdownStyled>
    );
};
