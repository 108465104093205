export const API_URL =
    process.env.REACT_APP_API_URL || // Если переменная задана в .env, используем её
    (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
        ? 'https://development.crypto.extract-sweet.ru/server' // Если переменная true
        : `${window.location.protocol}//${window.location.host.replace('account.', '')}/server`); // Если переменная false

export const EDENEX_ACCOUNT_URL =
    process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
        ? 'https://account.development.crypto.extract-sweet.ru/server' // Если переменная true
        : `${window.location.protocol}//${window.location.host}/server`


export const APP_NAME = 'account'

export const KEYCLOAK_URL =
    process.env.REACT_APP_KEYCLOAK_URL ||
    (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
        ? 'https://id.development.crypto.extract-sweet.ru'
        : `${window.location.protocol}//${window.location.host.replace('account.', '')}`);

// TODO why are different from web?
export const ACCOUNT_URL =
    process.env.REACT_APP_ACCOUNT_URL ||
    (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
        ? 'https://account.development.crypto.extract-sweet.ru'
        : `${window.location.protocol}//${window.location.host}`);

export const BASE_MAIN_URL = process.env.REACT_APP_EXCHANGE_URL ?? `${window.location.protocol}//${window.location.host.replace(
    'account.',
    ''
)}`

export const STOCK_EXCHANGE_URL = process.env.REACT_APP_STOCK_EXCHANGE_URL ?? `${window.location.protocol}//${window.location.host.replace(
    'account.',
    ''
)}`


export const FINMS_URL = `${window.location.protocol}//finmsapp.${window.location.host.replace(
    'account.', ''
)}`;

export const FINMS_URL_API =
    process.env.REACT_APP_FINMS_URL_API ||
    (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
        ? 'https://finmsapp.development.crypto.extract-sweet.ru'
        : `${window.location.protocol}//finmsapp.${window.location.host.replace(
            'account.',
            ''
        )}`);

export const EXCHANGE_MAP_API_URL =
    process.env.REACT_APP_API_EXCHANGE_MAP_URL ||
    (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
        ? 'https://exchangemap.development.crypto.extract-sweet.ru/server'
        : `${window.location.protocol}//exchangemap.${window.location.host.replace('account.', '')}/server`);


export const EXCHANGE_MAP_URL = `${window.location.protocol}//exchangemap.${window.location.host.replace(
    'account.', ''
)}`;


export const HUB_API_URL =
    process.env.REACT_APP_HUB_API_URL ||
    (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
        ? 'https://hub.development.crypto.extract-sweet.ru'
        : `${window.location.protocol}//hub.${window.location.host.replace('account.', '')}`);


export const CHAT_URL =
    process.env.REACT_APP_CHAT_URL ||
    (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
        ? 'https://chat.development.crypto.extract-sweet.ru'
        : `${window.location.protocol}//chat.${window.location.host.replace(
            'account.',
            ''
        )}`)


export const MAP_URL =
    process.env.REACT_APP_MAP_URL ||
    (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
        ? 'https://exchangemap.development.crypto.extract-sweet.ru'
        : `${window.location.protocol}//exchangemap.${window.location.host.replace(
            'account.',
            ''
        )}`);

export const KEYCLOAK_REALM = 'master';
export const KEYCLOAK_CLIENT_ID = 'crypto.account';

export const ACCOUNT_DATA_PROVIDER = 'account';
export const CONFIG_DATA_PROVIDER = 'config';

export const EXCHANGE_DATA_PROVIDER = 'exchange';
export const ETH_COIN_DATA_PROVIDER = 'eth-coin';
export const BTC_COIN_DATA_PROVIDER = 'btc-coin';

export const P2P_DATA_PROVIDER = 'p2p';

export const IS_SHOW_CHAT = true;

export const WS_URL =
    process.env.REACT_APP_WS_URL ||
    (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
        ? `wss://wss.development.crypto.extract-sweet.ru/centrifugo/connection/websocket`
        : `wss://wss.${window.location.host.replace('account.', '')}/centrifugo/connection/websocket`)
