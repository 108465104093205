import {ChatListItemOfferStepTimerStyled} from './assets/styles';
import GenericTimer from 'shared/ui/GenericTimer';
import {css, styled} from 'styled-components';
import dayjs from "dayjs";

const ChatListItemOfferStepTimer = ({
                                        createdAt,
                                    }: {
    createdAt: null | string;
}) => {
    const calcDeadline = () => {
        if (createdAt) {
            const date = dayjs(createdAt);
            const offset = dayjs().utcOffset();
            return date.add(offset, 'minutes')?.add(1, 'day').toISOString();
        } else return undefined
    }
    return (
        <ChatListItemOfferStepTimerStyled>
            <TimerWrapperStyled>
                <GenericTimer deadline={calcDeadline()}/>
            </TimerWrapperStyled>
        </ChatListItemOfferStepTimerStyled>
    );
};

export default ChatListItemOfferStepTimer;

export const TimerWrapperStyled = styled.div<{
    $isTimerDimmed?: boolean;
}>`
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    color: #ffffff80;

    .ant-statistic {
        font-size: 16px;

        .ant-statistic-content {
            padding-top: 8px;
            color: #ffffff;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
        }
    }

    ${({$isTimerDimmed}) =>
            $isTimerDimmed &&
            css`
                .ant-statistic {
                    .ant-statistic-content {
                        color: #474755;
                    }
                }
            `}
`;
